/* eslint-disable */
import React, { useEffect, useState, useRef, useCallback } from "react";
import styles from "./Offer.module.scss";
import Inputs from "../../Inputs";
import { useForm, Controller, useFieldArray } from "react-hook-form";
import { connect } from "react-redux";
import { listAgencies, addPolicyToUser, getCurrentOffer } from "../../../actions/offer";
import { supportSocketEmit } from "../../../actions/support";
import { getUserInfo } from "../../../actions/amarantFields";
import moment from "moment";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import { Divider } from "@mui/material";

const customStyles = {
  dropdownIndicator: (base, state) => ({
    ...base,
    color: "#0083E5", // Custom colour
    transition: "all .2s ease",
    transform: state.selectProps.menuIsOpen ? "rotate(180deg)" : null,
  }),
  option: (provided, state) => ({
    ...provided,
    borderBottom: "1px dotted pink",
    color: state.isSelected ? "white" : "black",
    backgroundColor: state.isSelected ? "#0083E5" : "white",
    padding: 10,
  }),
  control: () => ({
    // none of react-select's styles are passed to <Control />
    width: (window.innerWidth / 100) * 20,
    height: 40,
    display: "flex",
    border: "1px solid rgb(165, 164, 164)",
    borderRadius: "7px",
  }),
  singleValue: (provided, state) => {
    const opacity = state.isDisabled ? 0.5 : 1;
    const transition = "opacity 300ms";

    return { ...provided, opacity, transition };
  },
};
const fieldWords = ["Първа", "Втора", "Трета", "Четвърта"];
const typeKeywords = [
  "go",
  "kasko",
  "health",
  "property",
  "other",
  // "protection",
];

const AddPolicyToUser = ({ listAgencies, agencies, getUserInfo, user, addPolicyToUser, supportSocketEmit, getCurrentOffer }) => {
  const [instCount, setInstCount] = useState(1);
  const [filesCount, setFilesCount] = useState(1);
  const ticketID = window.location.href.split("/")[4];
  const customerID = window.location.href.split("/")[5];
  const offerID = window.location.href.split("/")[6];
  const [selCompany, setSelCompany] = useState({});
  const [policyType, setPolicyType] = useState("");
  const navigate = useNavigate();

  const {
    register,
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    customerSupport: true,
    customerSupportId: "",
    policyNumber: "",
    insuranceAgency: "",
    policyType: "",
    client: "",
    clientIdentifier: "",
    startDate: "",
    endDate: "",
    coverage: "",
    amount: "",
    installmentsCount: "",
    installments: [
      {
        date: "",
        price: null,
        paid: true,
      },
    ],
    files: [
      {
        name: "",
        url: "",
      },
    ],
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "files",
  });

  useEffect(() => {
    setValue("licensePlate", "");
  }, [policyType]);

  useEffect(() => {
    listAgencies({
      onSuccess: (response) => {
        if (offerID.length > 8) {
          getCurrentOffer({
            id: offerID,
            onSuccess: (res) => {
              append({
                name: "",
                url: "",
              });
              setValue("installmentsCount", `${res.installmentsCount}`);
              setInstCount(res.installmentsCount);
              setValue("customerSupportId", customerID);
              setValue("coverage", `${res?.insuranceSum}`);
              setValue("amount", `${res.premium}`);
              setValue("startDate", moment(res?.startDate?.split("T")[0])._d);
              setValue("endDate", moment(res?.endDate?.split("T")[0])._d);
              setValue("insuranceAgency", res.insuranceCompanyId);
              setSelCompany({
                label: response.filter((agency) => agency._id === res?.insuranceCompanyId)[0]?.name,
                value: res.insuranceCompanyId,
              });
              setValue("insuranceName", response.filter((agency) => agency._id === res?.insuranceCompanyId)[0]?.name);
              setValue(`installments.${0}.price`, res?.installmentOneSum);
              setValue(`installments.${0}.date`, moment(res?.installmentOneDate?.split("T")?.[0])?._d);
              if (res?.installmentTwoSum) {
                setValue(`installments.${1}.price`, res?.installmentTwoSum);
                setValue(`installments.${1}.date`, moment(res?.installmentTwoDate?.split("T")?.[0])?._d);
              }
              if (res?.installmentThreeSum) {
                setValue(`installments.${2}.price`, res?.installmentThreeSum);
                setValue(`installments.${2}.date`, moment(res?.installmentThreeDate?.split("T")?.[0])?._d);
              }
              if (res?.installmentFourSum) {
                setValue(`installments.${3}.price`, res?.installmentFourSum);
                setValue(`installments.${3}.date`, moment(res?.installmentFourDate?.split("T")?.[0])?._d);
              }
              getUserInfo({
                customerSupportId: customerID,
                onSuccess: (res) => {
                  setValue("client", res?.agreement?.fullName);
                  setValue("clientIdentifier", res?.agreement?.PIN);
                },
              });
            },
          });
        }
      },
    });
  }, []);

  return (
    <div className={styles["amarant-container"]}>
      <div className={styles["upper-info-container"]}>
        <h2 className={styles["title"]}>Добавяне на полица към профила</h2>
        <Inputs.Button
          text="Запази"
          className="green"
          style={{ marginRight: "1%", width: "130px" }}
          onClick={handleSubmit((e) => {
            const payload = {
              customerSupport: true,
              customerSupportId: customerID,
              licensePlate: e.licensePlate,
              policyNumber: e.policyNumber,
              insuranceAgency: e.insuranceAgency,
              phoneNumber: user.phoneNumber,
              policyType: e.policyType,
              client: e.client,
              clientIdentifier: e.clientIdentifier,
              startDate: moment(e.startDate).format("DD/MM/YYYY"),
              endDate: moment(e.endDate).format("DD/MM/YYYY"),
              coverage: e.coverage,
              amount: e.amount,
              installmentsCount: e.installmentsCount,
              installments: e.installments.map((install, i) => {
                return {
                  date: moment(install.date).format("DD/MM/YYYY"),
                  price: Number(install.price),
                  paid: i === 0 ? true : false,
                };
              }),
              files: e.files,
              onSuccess: (response) => {
                toast.success("Policy added to user successfully");
                const event = {
                  action: "sendMessage",
                  payload: {
                    ticket: ticketID,
                    variant: "policy",
                    text: "Вашата полица е добавена в профила:",
                    metaData: { _id: response._id },
                  },
                };
                supportSocketEmit(event);
                navigate(-1);
              },
            };
            if (policyType === "health") {
              delete payload.licensePlate;
              payload.policyInsuredObject = {
                type: "person",
                person: payload.client,
              };
            } else if (policyType === "property") {
              delete payload.licensePlate;
              payload.policyInsuredObject = {
                type: "address",
                address: e.address,
              };
            } else if (policyType === "other") {
              payload.policyInsuredObject = {
                type: "person",
                person: payload.client,
              };
            }
            addPolicyToUser(payload);
          })}
        />
      </div>
      <div className={styles["middle-info-container"]} style={{ height: "unset" }}>
        <h2 className={styles["title"]}>Основнa информация</h2>
        <div className={styles["main-parameters-container block"]}>
          <div
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "space-between",
              alignItems: "center",
              marginTop: "1%",
            }}
          >
            <Controller
              control={control}
              name={`insuranceAgency`}
              render={({ field: { value, onChange }, fieldState: { error } }) => (
                <Inputs.Dropdown
                  label={"Застрахователна компания"}
                  optionsArray={agencies?.map((agency) => {
                    return {
                      label: agency?.name,
                      value: agency?._id,
                    };
                  })}
                  compulsory
                  customStyles={customStyles}
                  style={{ width: "22%", height: "100%" }}
                  single
                  addPolicy={true}
                  pair={selCompany}
                  className={error && "failed"}
                  onChange={(e) => {
                    setValue("insuranceName", e.label);
                    setValue(`insuranceAgency`, e.value);
                    setSelCompany({
                      label: e.label,
                      value: e.value,
                    });
                  }}
                />
              )}
              rules={{ required: true }}
            />
            <Controller
              control={control}
              name={"startDate"}
              render={({ field: { value, onChange }, fieldState: { error } }) => (
                <Inputs.DatePicker
                  label={"Начална дата"}
                  compulsory
                  custom
                  style={{ width: "12%", height: "100%" }}
                  value={value}
                  onChange={(e) => {
                    setValue("startDate", e);
                    setValue("endDate", moment(e).add(1, "year").subtract(1, "day")?._d);
                  }}
                  className={error && "failed"}
                />
              )}
              rules={{ required: true }}
            />
            <Controller
              control={control}
              name={"endDate"}
              render={({ field: { value, onChange }, fieldState: { error } }) => (
                <Inputs.DatePicker
                  label={"Крайна дата"}
                  compulsory
                  custom
                  style={{ width: "12%", height: "100%", marginLeft: "2%" }}
                  value={value}
                  onChange={(e) => {
                    setValue("endDate", e);
                  }}
                  className={error && "failed"}
                />
              )}
              rules={{ required: true }}
            />
            <Controller
              control={control}
              name={"installmentsCount"}
              render={({ field: { value, onChange }, fieldState: { error } }) => (
                <div style={{ margin: "0% 3%" }}>
                  <label>Брой вноски</label>
                  <div style={{ display: "flex", marginTop: "5px" }}>
                    <Inputs.Button
                      text={1}
                      selected={value === "1"}
                      style={{ marginRight: "3%" }}
                      onClick={() => {
                        setValue("installmentsCount", "1");
                        setInstCount(1);
                      }}
                    />
                    <Inputs.Button
                      text={2}
                      selected={value === "2"}
                      style={{ marginRight: "3%" }}
                      onClick={() => {
                        setValue("installmentsCount", "2");
                        setInstCount(2);
                      }}
                    />
                    <Inputs.Button
                      text={3}
                      selected={value === "3"}
                      style={{ marginRight: "3%" }}
                      onClick={() => {
                        setValue("installmentsCount", "3");
                        setInstCount(3);
                      }}
                    />
                    <Inputs.Button
                      text={4}
                      selected={value === "4"}
                      style={{ marginRight: "3%" }}
                      onClick={() => {
                        setValue("installmentsCount", "4");
                        setInstCount(4);
                      }}
                    />
                  </div>
                </div>
              )}
              rules={{ required: true }}
            />
            <Inputs.TextInput label={"Застрахователна сума"} suffix={"лв."} style={{ width: "12%", marginRight: "1%" }} className={errors.coverage ? "failed" : ""} {...register(`coverage`)} />
            <Inputs.TextInput
              label={"Премия"}
              compulsory
              suffix={"лв."}
              type="number"
              style={{ width: "12%", marginRight: "2%" }}
              className={errors.amount ? "failed" : ""}
              {...register(`amount`, {
                required: true,
              })}
            />
          </div>
          <div
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "space-between",
              alignItems: "center",
              marginTop: "1%",
            }}
          >
            <Controller
              control={control}
              name={`policyType`}
              render={({ field: { value, onChange }, fieldState: { error } }) => (
                <Inputs.Dropdown
                  label={"Вид"}
                  optionsArray={typeKeywords?.map((type) => {
                    return {
                      label: type,
                      value: type,
                    };
                  })}
                  compulsory
                  customStyles={customStyles}
                  style={{ width: "20%", height: "100%" }}
                  single
                  value={value}
                  className={error && "failed"}
                  onChange={(e) => {
                    setValue(`policyType`, e.value);
                    setPolicyType(e.value);
                  }}
                />
              )}
              rules={{ required: true }}
            />
            <Inputs.TextInput
              label={"Номер на полица"}
              compulsory
              style={{ width: "12%", marginRight: "1%" }}
              className={errors.policyNumber ? "failed" : ""}
              {...register(`policyNumber`, {
                required: true,
              })}
            />
            {policyType === "property" ? (
              <Inputs.TextInput
                label={"Адрес"}
                compulsory
                style={{ width: "20%", marginRight: "1%" }}
                className={errors.address ? "failed" : ""}
                {...register(`address`, {
                  required: true,
                })}
              />
            ) : policyType === "health" || policyType === "property" || policyType === "other" ? null : (
              <Inputs.TextInput
                label={"Рег.номер (латиница)"}
                compulsory={policyType !== "other"}
                style={{ width: "12%", marginRight: "1%" }}
                className={errors.licensePlate ? "failed" : ""}
                {...register(`licensePlate`, {
                  required: policyType === "other" ? false : true,
                  pattern: /^[a-z0-9]+$/i,
                })}
              />
            )}

            <Inputs.TextInput
              label={"Застрахован"}
              compulsory
              style={{ width: "25%", marginRight: "1%" }}
              className={errors.client ? "failed" : ""}
              {...register(`client`, {
                required: true,
              })}
            />
            <Inputs.TextInput
              label={"ЕГН"}
              compulsory
              style={{ width: "12%", marginRight: "1%" }}
              className={errors.clientIdentifier ? "failed" : ""}
              {...register(`clientIdentifier`, {
                required: true,
              })}
            />
          </div>
        </div>
      </div>
      <div className={styles["middle-info-container"]} style={{ height: "unset" }}>
        <h2 className={styles["title"]}>Вноски</h2>
        <div className={styles["main-parameters-container"]}>
          {Array(instCount)
            .fill("")
            .map((el, i) => {
              return (
                <React.Fragment key={i}>
                  <Inputs.TextInput
                    label={`${fieldWords[i]} вноска сума`}
                    compulsory
                    suffix={"лв."}
                    style={{ width: "12%", marginRight: "1%" }}
                    className={errors.coverage ? "failed" : ""}
                    {...register(`installments.${i}.price`, {
                      required: true,
                    })}
                  />
                  <Controller
                    control={control}
                    name={`installments.${i}.date`}
                    render={({ field: { value, onChange }, fieldState: { error } }) => <Inputs.DatePicker label={`${fieldWords[i]} вноска падеж`} compulsory style={{ width: "12%", height: "100%" }} value={value} onChange={(e) => setValue(`installments.${i}.date`, e)} className={error && "failed"} />}
                    rules={{ required: true }}
                  />
                  {instCount > 1 && i < instCount - 1 ? (
                    <Divider
                      style={{
                        width: "2px",
                        height: "65px",
                        backgroundColor: "#0DC1CE",
                        margin: "5px 10px",
                      }}
                    />
                  ) : null}
                </React.Fragment>
              );
            })}
        </div>
      </div>
      <div className={styles["middle-info-container"]} style={{ height: "unset", overflowY: "auto" }}>
        <h2 className={styles["title"]}>Документи към полица</h2>
        <div className={styles["main-parameters-container block"]}>
          {fields.map((el, i) => {
            return (
              <div
                style={{
                  display: "flex",
                  margin: "2% 0%",
                  alignItems: "center",
                }}
                key={el.id}
              >
                {fields?.length > 1 && <div className="close-icon" style={{ marginRight: "2%" }} onClick={() => remove(i)} />}
                <Inputs.TextInput
                  label={`Име на документ`}
                  compulsory
                  key={el.id}
                  style={{ width: "40%", marginRight: "1%" }}
                  className={errors?.files?.[i]?.name ? "failed" : ""}
                  {...register(`files.${i}.name`, {
                    required: true,
                  })}
                />
                <Controller
                  control={control}
                  name={`files.${i}.url`}
                  render={({ field: { value, onChange }, fieldState: { error } }) => (
                    <>
                      <Inputs.ImageInput
                        label={"Документ"}
                        compulsory
                        style={{ width: "25%", margin: "10px 0px" }}
                        value={value}
                        specialDir={true}
                        info={{
                          keyword: "policy",
                          id: ticketID,
                          date: moment().format("DD.MM.YYYY"),
                        }}
                        key={"documents"}
                        onChange={(e) => setValue(`files.${i}.url`, e)}
                        setValue={setValue}
                        className={error && "failed"}
                      />
                    </>
                  )}
                  rules={{ required: true }}
                />
              </div>
            );
          })}
          <div
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "flex-end",
            }}
          >
            <Inputs.Button
              text={"Добави нов"}
              style={{ width: "10%" }}
              className="dotted"
              onClick={() => {
                append({
                  name: "",
                  url: "",
                });
                setFilesCount(filesCount + 1);
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
const mapStateToProps = (state) => ({
  agencies: state.offer.agencies,
  user: state.amarantFields.user,
});
const mapDispatchToProps = (dispatch) => ({
  listAgencies: (payload) => dispatch(listAgencies(payload)),
  createOffer: (payload) => dispatch(createOffer(payload)),
  getUserInfo: (payload) => dispatch(getUserInfo(payload)),
  addPolicyToUser: (payload) => dispatch(addPolicyToUser(payload)),
  supportSocketEmit: (payload) => dispatch(supportSocketEmit(payload)),
  getCurrentOffer: (payload) => dispatch(getCurrentOffer(payload)),
});
export default connect(mapStateToProps, mapDispatchToProps)(AddPolicyToUser);
